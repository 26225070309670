import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ScrollUpButton from "react-scroll-up-button";

import Layout from "../components/layout";
import Seo from "../components/seo";

const landing = () => {
    return (
        <Layout pageInfo={{ pageName: "Terms" }}>
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="DeFi Powered Rewards Generator" />
            <div className="py-140 mb-4  text-justified ">
                <Container>
                    <Row className="mb-2 justify-content-center ">
                        <img src='/wechat.jpg' alt="Hedge Pay Crypto Finance" />
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default landing;